import React, { useRef, useEffect } from 'react';
import { TextButton } from './TextButton';
import { IconButton } from './IconButton';
import './ImporterFrame.scss';
export const ImporterFrame = ({ fileName, subtitle, secondaryDisabled, secondaryLabel, nextDisabled, nextLabel, error, onSecondary, onNext, onCancel, children }) => {
    const titleRef = useRef(null);
    const subtitleRef = useRef(null);
    useEffect(() => {
        if (subtitleRef.current) {
            subtitleRef.current.focus();
        }
        else if (titleRef.current) {
            titleRef.current.focus();
        }
    }, []);
    return (React.createElement("div", { className: "CSVImporter_ImporterFrame" },
        React.createElement("div", { className: "CSVImporter_ImporterFrame__header" },
            React.createElement(IconButton, { label: "Go to previous step", type: "arrowBack", disabled: !onCancel, onClick: onCancel }),
            React.createElement("div", { className: "CSVImporter_ImporterFrame__headerTitle", tabIndex: -1, ref: titleRef }, fileName),
            subtitle ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "CSVImporter_ImporterFrame__headerCrumbSeparator" },
                    React.createElement("span", null)),
                React.createElement("div", { className: "CSVImporter_ImporterFrame__headerSubtitle", tabIndex: -1, ref: subtitleRef }, subtitle))) : null),
        children,
        React.createElement("div", { className: "CSVImporter_ImporterFrame__footer" },
            React.createElement("div", { className: "CSVImporter_ImporterFrame__footerFill" }),
            error ? (React.createElement("div", { className: "CSVImporter_ImporterFrame__footerError", role: "status" }, error)) : null,
            secondaryLabel ? (React.createElement("div", { className: "CSVImporter_ImporterFrame__footerSecondary" },
                React.createElement(TextButton, { disabled: !!secondaryDisabled, onClick: onSecondary }, secondaryLabel))) : null,
            React.createElement(TextButton, { disabled: !!nextDisabled, onClick: onNext }, nextLabel || 'Next'))));
};
